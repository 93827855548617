import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { InternalHeader } from './InternalHeader'
import { SubHeader } from './styled'
import Images from '../../../images'
import { getLanguageWordsByLanguageKey } from '../../../services/languagesData'
import { useTranslationInternal } from '../../../services/helpers'

const CircleBasicSteps = styled.div`
  display: flex;
  width: 22px;
  height: 22px;
  border: 1.5px solid #6297FF;
  background: #6297FF;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`

const Circle = (props) => {
  const { style, disabled, completed } = props

  if (completed) {
    return <CircleBasicSteps style={{ ...style }}>
      <Images.CheckedInline />
    </CircleBasicSteps>

  }

  return (
    <CircleBasicSteps style={{
      borderColor: disabled ? '#E5EBF8' : '#6297FF',
      backgroundColor: disabled ? '#E5EBF8' : 'transparent',
      ...style
    }}>
    </CircleBasicSteps>
  )

}

const SingleToDo = (props) => {
  const { label, disabled, completed } = props
  if (disabled && !completed) {
    return <div style={{ display: 'flex', flexDirection: 'row', marginTop: 16 }}>
      <Circle disabled={disabled} />
      <div style={{ display: 'flex', flex: 1 }}>
        <p style={{ color: '#98A6C7', fontWeight: 600, marginLeft: 17, fontSize: 15 }}>
          {label}
        </p>
      </div>
    </div>
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 16 }}>
      <Circle completed={completed} />
      <p style={{ color: '#1E222D', fontWeight: 600, marginLeft: 17, fontSize: 15, display: 'flex', flex: 1 }}>
        {label}
      </p>
    </div>
  )
}

const ExampleWords = (props) => {
  return (
    <>
      {getLanguageWordsByLanguageKey(props.language).map(word => {
        return <p style={{color: '#1E222D', marginBottom: 8}}>{word}</p>
      })}
    </>
  )
}

export const ExampleUsage = (props) => {
  const { nickname, withoutHeader, setNickname, nicknameError, currentStep, stepsCount, languagesToLearn } = props
  const languageToLearn = languagesToLearn[0]
  const [hasUserTranslatedWord, setUserTranslatedWord] = useState(false)
  const [userSelectedWord, setUserSelectedWord] = useState(false)
  const [savedWord, setUserSavedWord] = useState(false)
  const [displayFinishStep, setDisplayFinishStep] = useState(false)
  const t = useTranslationInternal()

  const handleMessage = useCallback(event => {
    if (event.data && event.data.type === "TRANSLATED_WORD") {
      setUserTranslatedWord(true)
    }

    if (event.data && event.data.type === "SELECTED_WORD") {
      setUserSelectedWord(true)
    }

    if (event.data && event.data.type === "SAVED_WORD") {
      setUserSavedWord(true)
      setTimeout(() => {
        setDisplayFinishStep(true)
      }, 1000)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('message', handleMessage, false)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  return (
    <div style={{ width: 400, margin: '0 auto', textAlign: 'left' }}>
      {!withoutHeader && <SubHeader style={{ marginTop: 0, textAlign: 'left' }}
                                    isActive>{t('onboarding.translationInExtension')}</SubHeader>}
      <InternalHeader currentStep={currentStep} stepsCount={stepsCount()}/>
      <div
        style={{ border: '1px solid #D5DEF1',textAlign: "center", padding: 24, paddingBottom: 16, borderRadius: 8, marginTop: 24, marginBottom: 24 }}
      >
        {languageToLearn && <ExampleWords language={languageToLearn}/>}
      </div>
      <div>
        {!displayFinishStep &&
        <>
          <SingleToDo disabled={userSelectedWord && hasUserTranslatedWord}
                      label={t('onboarding.translationInExtension.markTheWord')} completed={userSelectedWord}/>
          <SingleToDo disabled={!userSelectedWord} label={t('onboarding.translationInExtension.clickTheWord')}
                      completed={hasUserTranslatedWord}/>
            <SingleToDo disabled={!hasUserTranslatedWord} label={t("onboarding.translationInExtension.addTheWord")} completed={savedWord} />
          </>
        }

        {displayFinishStep &&
          <div>
            <div className="is-flex is-justify-content-center" style={{ marginTop: 16 }}>
              <article className="message is-info" style={{ width: '100%', lineHeight: '160%' }}>
                <div
                  className="message-body"
                  style={{ borderWidth: 0, background: '#EAF3FF', fontSize: 13, borderRadius: 8, color: '#6297FF', fontWeight: 500, textAlign: 'left' }}
                >
                  {t('onboarding.congratulationsForFinishedExample')}
                </div>
              </article>
            </div>
          </div>
        }
      </div>
    </div>
  )
}