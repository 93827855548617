import React, { useEffect, useState } from 'react'
import { Transition } from 'react-transition-group'
import Images from '../../../images/'
import { useApiBase } from '../../../services/api'
import { chromeSendMessage } from '../../../services/chrome'
import { trackEvent, useTranslationInternal } from '../../../services/helpers'
import '../../../styles/style.scss'
import { LanguageDropdown } from '../Auth/Auth'
import { StyledLink } from '../Settings/commons'
import { Outro } from './Outro'
import { STEPS } from './steps'
import { Button } from './styled'
import { defaultStyleRightColumn, transitionStylesRightColumn } from './transitionStyles'
import { useRedirectHook } from './useRedirectHook'

const INITIAL_STEP = 0
const STEPS_TO_SKIP_WHEN_HAS_MOBILE_APP = ['static_screen_mobile']

const filterUnnecessarySteps = (hasFinishedRequiredOnboardingSteps, hasMobileApp) => (onboardingStep) => {
  if (hasMobileApp && STEPS_TO_SKIP_WHEN_HAS_MOBILE_APP.includes(onboardingStep.stepName)) {
    return false
  }
  return hasFinishedRequiredOnboardingSteps ? onboardingStep.displayInSimplicatedOnboarding : true
}

export const GetStarted = ({ style, hasFinishedRequiredOnboardingSteps, extensionSettings, hasMobileApp }) => {
  const api = useApiBase()
  const t = useTranslationInternal()

  const [step, setStep] = useState(INITIAL_STEP)
  const [nativeLanguageError, setNativeLanguageError] = useState('')
  const [foreignLanguageError, setForeignLanguageError] = useState('')
  const [motivationError, setMotivationError] = useState('')
  const [userMotivations, setUserMotivations] = useState([])
  const [otherMotivation, setOtherMotivation] = useState('')
  const hasFinishedOnboarding = extensionSettings?.onboarding_finished === true

  const {
    languagesToLearn,
    nativeLanguage,
    setLanguagesToLearn,
    setNativeLanguage,
    setUserMotivationFilled, // TODO deprecated
  } = useRedirectHook()

  useEffect(() => {
    setNativeLanguageError('')
    setNativeLanguageError('')
  }, [nativeLanguage, languagesToLearn])

  const validate = (stepName) => {
    if (stepName === 'native' && nativeLanguage === null) {
      setNativeLanguageError(t('onboarding.chooseNativeLanguage'))
      return false
    } else if (stepName === 'foreign' && languagesToLearn.length === 0) {
      setForeignLanguageError(t('onboarding.chooseLanguagesToLearn'))
      return false
    }

    return true
  }

  const showErrors = () => {
    alert('Something has gone wrong. Let\'s try it again')
    window.location.href = '/app/login'
  }

  const saveLanguages = async (nextStep) => {
    chromeSendMessage({
      type: 'languagesToLearn',
      place: 'onboarding',
      languagesToLearn: languagesToLearn,
    })

    chromeSendMessage({
      type: 'nativeLanguage',
      language: nativeLanguage,
      place: 'onboarding'
    })

    trackEvent('Native Lang Selected', {
      native_lang: nativeLanguage,
    })
    trackEvent('Learning Lang Selected', {
      learning_lang: languagesToLearn
    })
    const result = await api.updateUser({
      nativeLanguage: nativeLanguage,
      learningLanguages: languagesToLearn,
      weeklyGoal: 7
    })

    if (result.ok) {
      setStep(nextStep)
    } else {
      showErrors()
    }
  }

  const saveMotivations = async (nextStep) => {
    if (userMotivations.length === 0 && otherMotivation.length === 0) {
      setMotivationError(t('onboarding.chooseMotivation'))
    } else {
      const allMotivations = [...userMotivations]
      if (otherMotivation.length > 0) {
        allMotivations.push(`other - ${otherMotivation}`)
      }
      const result = await api.updateUserDetails({ motivation: allMotivations })
      trackEvent('Motivation Selected', {
        motivation: allMotivations
      })

      if (result.ok) {
        setStep(nextStep)
      } else {
        showErrors()
      }
    }
  }

  const onClick = (stepType) => () => {
    const nextStep = step + 1
    if (validate(stepType)) {
      if (stepType === 'motivation') {
        saveMotivations(nextStep)
      } else if (stepType === 'foreign') {
        setForeignLanguageError('')
        saveLanguages(nextStep)
      } else {
        setStep(nextStep)
      }
    }
  }

  const goBack = () => {
    const previousStep = step - 1
    setStep(previousStep)
  }

  const skipStep = (stepName) => () => {
    onClick(stepName)()
  }

  const getLatestStep = () => {
    return hasFinishedRequiredOnboardingSteps
      ? STEPS.filter(filterUnnecessarySteps(hasFinishedRequiredOnboardingSteps, hasMobileApp)).length
      : STEPS.length
  }

  if (hasFinishedOnboarding || step === getLatestStep()) {
    return <Outro extensionSettings={extensionSettings}/>
  }

  return (
    <>
      {step !== getLatestStep() && (
        <section className="hero is-fullheight" style={style}>
          <div className="hero-head"
               style={{ marginTop: 40, display: 'flex', justifyContent: 'center', position: 'relative' }}>
            <img
              src={Images.logoYellowWhite}
              srcSet={`${Images.logoYellowWhite3x} 3x, ${Images.logoYellowWhite2x} 2x`}
              style={{ zIndex: 999, width: 128 }}
            />
            <div style={{ marginTop: -10, position: 'absolute', left: '50%', marginLeft: 170 }}>
              <LanguageDropdown/>
            </div>
          </div>
          <div className="hero-body" style={{ alignItems: 'flex-start', paddingTop: 24 }}>
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-half has-text-centered" style={{ maxWidth: 400 }}>
                  {STEPS.filter(filterUnnecessarySteps(hasFinishedRequiredOnboardingSteps, hasMobileApp)).map((item, index) => {
                    const Content = item.Content
                    return (
                      <>
                        <Transition
                          in={step === index}
                          timeout={50}
                        >
                          {state => (
                            <div style={{
                              ...defaultStyleRightColumn,
                              ...transitionStylesRightColumn[state],
                              width: 400
                            }}>
                              {step > 0 && <button onClick={goBack} type="button" style={{
                                cursor: 'pointer',
                                border: 'none',
                                background: 'none',
                                outline: 'none',
                                justifyContent: 'flex-start',
                                display: 'flex'
                              }}>
                                <Images.ArrowLeft/>
                              </button>
                              }
                              <Content
                                languagesToLearn={languagesToLearn}
                                setStep={setStep}
                                nativeLanguage={nativeLanguage}
                                setNativeLanguage={setNativeLanguage}
                                setLanguagesToLearn={setLanguagesToLearn}
                                currentStep={step}
                                stepsCount={getLatestStep}
                                nativeLanguageError={nativeLanguageError}
                                foreignLanguageError={foreignLanguageError}
                                motivationError={motivationError}
                                setUserMotivationFilled={setUserMotivationFilled}
                                setUserMotivations={setUserMotivations}
                                setOtherMotivation={setOtherMotivation}
                                otherMotivation={otherMotivation}
                                userMotivations={userMotivations}
                              />

                              <Button
                                style={{ marginTop: 32, marginBottom: 16, width: '100%' }}
                                onClick={onClick(item.stepType || item.stepName)}
                              >
                                {t('onboarding.next')}
                              </Button>

                              {item.isSkipable &&
                                <StyledLink onClick={skipStep(item.stepName)} button
                                            label={t('onboarding.skipStep')}></StyledLink>
                              }

                            </div>
                          )}
                        </Transition>
                      </>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}