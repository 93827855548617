export const duration = 500

export const defaultStyle = {
  transition: `opacity 300ms ease-in-out, max-height 500ms`,
  opacity: 0,
  maxHeight: 700
}

export const transitionStyles = {
  entering: { opacity: 1, maxHeight: 700 },
  entered: { opacity: 1, maxHeight: 700 },
  exiting: { opacity: 0, maxHeight: 0, overflow: 'hidden' },
  exited: { opacity: 0, maxHeight: 0, overflow: 'hidden' },
};

export const defaultStyleRightColumn = {
  transition: `opacity 300ms ease-in-out, max-height 500ms`,
  opacity: 0,
  maxHeight: 700
}

export const transitionStylesRightColumn = {
  entering: { opacity: 1, maxHeight: 700, },
  entered: { opacity: 1, maxHeight: 700 },
  exiting: { opacity: 0, maxHeight: 0, overflow: 'hidden', width: 0 },
  exited: { opacity: 0, maxHeight: 0, overflow: 'hidden', width: 0 },
};
