import React from 'react'
import Images from '../../../images'
import { useTranslationInternal } from '../../../services/helpers'
import { InternalHeader } from './InternalHeader'
import { SubHeader } from './styled'

export const StaticScreen = (props) => {
  const { withoutHeader, currentStep, stepsCount } = props
  const t = useTranslationInternal()
  return (
    <div style={{ width: 400, margin: '0 auto', textAlign: 'left' }}>
      {!withoutHeader && <SubHeader style={{ marginTop: 0, textAlign: 'left' }}
                                    isActive>{t('static.header')}</SubHeader>}
      <InternalHeader currentStep={currentStep} stepsCount={stepsCount()}/>
      <p style={{ fontSize: 15, color: '#1E222D', fontWeight: 600 }}>
        {t('static.copy')}
      </p>
      <div>
        <div className="is-flex is-justify-content-center" style={{ marginTop: 12 }}>
          <article className="message is-info" style={{ width: '100%', lineHeight: '126%' }}>
            <div
              className="message-body"
              style={{
                borderWidth: 0,
                background: '#EAF3FF',
                fontSize: 13,
                borderRadius: 8,
                color: '#6297FF',
                fontWeight: 500,
                textAlign: 'left'
              }}
            >
              {t('static.premium')}
            </div>
          </article>
        </div>
      </div>
      <div style={{ textAlign: 'center', marginTop: 32 }}>
        <img
          src={Images.MobileAppLearning}
          srcSet={`${Images.MobileAppLearning2x} 2x, ${Images.MobileAppLearning3x} 3x`}
        />
      </div>
    </div>

  )
}