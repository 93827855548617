import { navigate } from 'gatsby-link'
import React, { useEffect, useState } from 'react'
import { useApiBase } from '../../../services/api'
import { saveAndSendAuthDataToExtension } from '../../../services/auth0/useAuth0'
import { chromeSendMessage } from '../../../services/chrome'
import { trackIdentify } from '../../../services/helpers'

export const useRedirectHook = () => {
  const api = useApiBase()
  const [nativeLanguage, setNativeLanguage] = useState(null)
  const [languagesToLearn, setLanguagesToLearn] = useState([])
  const [userMotivationFilled, setUserMotivationFilled] = useState(false) // deprecated
  const [userLanguageLevelFilled, setUserLanguageLevelFilled] = useState(false)
  const [extensionSettings, setExtensionSettings] = useState(null)

  useEffect(() => {
    api.getUser().then(response => {
      if (response.ok) {
        if (response.data.details) {
          setUserMotivationFilled(response.data.details.motivation !== null)
          setUserLanguageLevelFilled(
            !!response.data.details[`language_level_${response.data.learning_languages[0]}`]
          )
        }
        setLanguagesToLearn(response.data.learning_languages)
        setNativeLanguage(response.data.native_language)
        setExtensionSettings(response.data.extension_settings)
        const loginData = JSON.parse(localStorage.getItem('underline'))
        trackIdentify(response.data.id, response.data.email)
        chromeSendMessage({
          type: 'identify',
          id: response.data.id
        })

        saveAndSendAuthDataToExtension(
          loginData.accessToken,
          loginData.refreshToken,
          loginData.expiresAt,
          loginData.idToken
        )

        if (response.data.native_language && response.data.learning_languages.length > 0) {
          chromeSendMessage({
            place: 'simplicated_onboarding',
            type: 'languagesToLearn',
            languagesToLearn: response.data.learning_languages,
          })

          chromeSendMessage({
            place: 'simplicated_onboarding',
            type: 'nativeLanguage',
            language: response.data.native_language,
          })
        }

      } else {
        navigate('/app/login')
      }
    })

  }, [])

  return {
    nativeLanguage,
    setNativeLanguage,
    languagesToLearn,
    setLanguagesToLearn,
    setUserMotivationFilled,
    userLanguageLevelFilled,
    extensionSettings
  }
}