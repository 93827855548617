import React, { useEffect } from "react"
import { AllAnimationsCombined } from '../../../components/Animation/Animation'
import { trackPage } from "../../../services/helpers"
import '../../../styles/style.scss'
import { GetStarted } from './GetStarted'

export const Onboarding = (props) => {
  const hasFinishedRequiredOnboardingSteps = props?.location?.state?.hasFinishedRequiredOnboardingSteps === true
  const extensionSettings = props?.location?.state?.extensionSettings;
  const hasMobileApp = props?.location?.state?.hasMobileApp

  useEffect(() => {
    trackPage("Onboarding Page")
  }, [])

  return (
    <>
      <AllAnimationsCombined singlePage />
      <GetStarted hasFinishedRequiredOnboardingSteps={hasFinishedRequiredOnboardingSteps} extensionSettings={extensionSettings} hasMobileApp={hasMobileApp}/>
    </>
  )
}