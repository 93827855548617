import React from 'react';
import { SingleItemList } from '../../../components/SingleLanguage/SingleLanguage';
import { useApiBase } from '../../../services/api';
import { useTranslationInternal } from '../../../services/helpers';
import { Error, SingleField } from '../../Landing/Contact';
import { InternalHeader } from './InternalHeader';
import { SubHeader } from './styled'
import CustomScroller from 'react-custom-scroller'

const options = [
  {
    key: 'career_improvement',
    label: 'userMotivation.career_improvement',
  },
  {
    key: 'communication_with_friends',
    label: 'userMotivation.communication_with_friends',
  },
  {
    key: 'career_change',
    label: 'userMotivation.career_change',
  },
  {
    key: 'learning_hobby',
    label: 'userMotivation.learning_hobby',
  },
  {
    key: 'better_grades',
    label: 'userMotivation.better_grades',
  },
  {
    key: 'work_abroad',
    label: 'userMotivation.work_abroad',
  },
  {
    key: 'foreign_exchange',
    label: 'userMotivation.foreign_exchange',
  },
]

export const UserMotivation = (props) => {
  const { withoutHeader, currentStep, stepsCount } = props
  const t = useTranslationInternal()
  const api = useApiBase()

  const handleOnClick = (motivation) => () => {
    if (props.userMotivations.includes(motivation)) {
      props.setUserMotivations(props.userMotivations.filter(item => item !== motivation))
    } else {
      props.setUserMotivations([...props.userMotivations, motivation])
    }
  }

  return (
    <div style={{ width: 400, margin: '0 auto', textAlign: 'left' }}>
      {!withoutHeader &&
        <SubHeader style={{ marginTop: 0, textAlign: 'left' }} isActive>
          {t("onboarding.motivation.header")}
        </SubHeader>
      }
      <InternalHeader currentStep={currentStep} stepsCount={stepsCount()} />
      <Error withBorder text={props.motivationError}/>
      <CustomScroller className="scrollerCustom" style={{ height: 354, maxWidth: 400, marginTop: 24 }}>
        {options.map(item =>
          <SingleItemList
            index={item.key}
            onClick={handleOnClick(item.key)}
            isSelected={props.userMotivations.includes(item.key)}
            item={{
              label: t(item.label)
            }}
          />
        )}
        <SingleField
          borderWhenFilled
          style={{ marginBottom: 0 }}
          inputProps={{
            onBlur: () => {},
            name: 'motivation',
            label: '',
            placeholder: t('userMotivation.other'),
            inputType: 'input',
            handleChangeField: (e) => props.setOtherMotivation(e.target.value),
            value: props.otherMotivation
          }}
        />
      </CustomScroller>
    </div>
  )
}