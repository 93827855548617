import React from 'react'
import { LanguagesToLearnSelection } from './LanguagesToLearnSelection'
import { NativeLanguageSelection } from './NativeLanguageSelection'
import { ExampleUsage } from './ExampleUsage'
import { StaticScreen } from './Static'
import { UserMotivation } from './UserMotivation'
import { LanguageLevel } from './LanguageLevel'

export const STEPS = [
  {
    stepType: 'native',
    stepName: 'native_language',
    isSkipable: false,
    Content: (props) => <NativeLanguageSelection {...props} />,
  },
  {
    stepType: 'foreign',
    stepName: 'foreign_languages',
    isSkipable: false,
    Content: (props) => <LanguagesToLearnSelection {...props} />,
  },
  {
    stepName: 'motivation',
    isSkipable: false,
    Content: (props) => <UserMotivation {...props} />,
  },
  {
    stepName: 'languageLevel',
    isSkipable: true,
    Content: (props) => <LanguageLevel {...props} />,
  },
  {
    stepName: 'example_usage',
    displayInSimplicatedOnboarding: true,
    isSkipable: true,
    Content: (props) => <ExampleUsage {...props} />,
  },
  {
    stepName: 'static_screen_mobile',
    displayInSimplicatedOnboarding: true,
    isSkipable: false,
    Content: (props) => <StaticScreen {...props} />,
  },
]