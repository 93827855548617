import React, { useState } from 'react'
import CustomScroller from 'react-custom-scroller';

import { InternalHeader } from './InternalHeader';
import { SubHeader } from './styled';
import { trackEvent, trackIdentifyAnonymous, useTranslationInternal } from '../../../services/helpers';
import { SingleItemList } from '../../../components/SingleLanguage/SingleLanguage';
import { useApiBase } from '../../../services/api';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { findLanguageByLanguageKey } from '../../../services/languagesData';

const options = [
  {
    label: 'languageLevel.a1a2',
    key: 'a1_a2'
  },
  {
    label: 'languageLevel.b1b2',
    key: 'b1_b2'
  },
  {
    label: 'languageLevel.c1c2',
    key: 'c1_c2'
  },

]

export const LanguageLevel = (props) => {
  const { withoutHeader, currentStep, stepsCount } = props
  const availableLangulages = props.languagesToLearn.map(item => ({ [item]: null }))
  const [languageLevel, setLanguageLevel] = useState(availableLangulages)
  const t = useTranslationInternal()
  const { t: t2 } = useI18next()
  const api = useApiBase()

  const handleOnClick = (level, languageKey) => () => {
    const isUnselecting = languageLevel[languageKey] === level
    const selectedLevel = isUnselecting ? null : level
    api.updateUserDetails({ [`language_level_${languageKey}`]: level })
    const currentLanguageLevel = languageLevel
    
    setLanguageLevel({
      ...currentLanguageLevel,
      [languageKey]: selectedLevel
    })
    
    trackEvent("Level Selected", {
      lang: languageKey,
      level_lang: selectedLevel
    })
  }

  return (
    <div style={{ width: 400, margin: '0 auto', textAlign: 'left' }}>
      {!withoutHeader &&
        <SubHeader style={{ marginTop: 0, textAlign: 'left' }} isActive>
          {t("onboarding.languageLevel.header")}
        </SubHeader>
      }
      <InternalHeader currentStep={currentStep} stepsCount={stepsCount()} />
      <CustomScroller className="scrollerCustom" style={{ height: 354, maxWidth: 400, marginTop: 24 }}>
        {props.languagesToLearn.map(languageShortcut => {
          return (
            <>
              <p style={{ marginBottom: 16, color: '#1E222D', fontWeight: 600, fontFamily: 'Poppins', fontSize: 15 }}>
                {t2(`languages.${findLanguageByLanguageKey(languageShortcut).language}`)}
              </p>
              {options.map((item, index) =>
                <>
                  <SingleItemList
                    index={item.key}
                    onClick={handleOnClick(item.key, languageShortcut)}
                    isSelected={languageLevel[languageShortcut] === item.key}
                    item={{
                      label: t(item.label)
                    }}
                  />

                </>
              )}
            </>
          )
        })}

      </CustomScroller>
    </div>
  )
}