import React, { useEffect } from 'react'
import { Button } from '../../../components/Button/Button'
import Images from '../../../images'
import { Header } from './styled'
import { trackEvent, useTranslationInternal } from '../../../services/helpers'
import { useApiBase } from '../../../services/api'

export const Outro = ({ style, extensionSettings }) => {
  const t = useTranslationInternal()
  const api = useApiBase()
  const hasOnboardingFinished = extensionSettings?.onboarding_finished

  useEffect(() => {
    if (!extensionSettings || !hasOnboardingFinished) {
      api.updateSettings({
        ...extensionSettings,
        onboarding_finished: true,
      })
      trackEvent('Onboarding Finished')
    }
  }, [])

  const tryIt = () => {
    trackEvent('Practice Clicked')
    window.location.href = 'https://medium.com'
    // navigate("https://medium.com")
  }

  return (
    <section className="hero is-fullheight" style={style}>
      <div className="hero-head" style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          src={Images.logoYellowWhite}
          srcSet={`${Images.logoYellowWhite3x} 3x, ${Images.logoYellowWhite2x} 2x`}
          style={{ zIndex: 999, width: 128, marginTop: 80, marginBottom: 80 }}
        />
      </div>
      <div className="hero-body" style={{ alignItems: 'flex-start' }}>

        <div className="container">
          <div className="columns is-vcentered is-centered">
            <div className="column is-half has-text-centered">
              <img
                style={{ minHeight: 200 }}
                src={Images.thankYou}
                srcSet={`${Images.thankYou2x} 2x, ${Images.thankYou3x} 3x`}
              />
              <Header className="title " style={{ marginTop: 47 }}>
                {hasOnboardingFinished ? t('outro.youHaveLogged') : t('thanks')}
              </Header>
              {!hasOnboardingFinished &&
                <Header className="subtitle">
                  {t('outro.extensionActivated')}
                </Header>
              }
              <Button
                onClick={() => tryIt()}
                style={{ width: 400, marginTop: 8, height: 42, padding: 10, fontSize: 13 }}
              >
                {hasOnboardingFinished ? t('outro.startUsing') : t('onboarding.tryInPractice')}
              </Button>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}