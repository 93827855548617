import { Onboarding } from '../../containers/WebApp/Onboarding/Onboarding';

export default Onboarding

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;